.shop-abstract {
    background-color: $rsr-primary;
    .wrapper {
        display: flex;
        @include respond-to("iphone6-plus") {
            flex-wrap: wrap;
        }
    }
    .wrapper > div {
        box-sizing: border-box;
        height: 498px;
        border: 1px solid #FFFFFF;
        margin-bottom: 60px;

        &:first-child {
            width: 68px;
            display: flex;
            align-items: center;
            justify-content: center;
            p {
                color: white;
                writing-mode: tb-rl;
                transform: rotate(-180deg);
                font-size: 14px;
                font-weight: 300;
                letter-spacing: 3.5px;
                line-height: 17px;
            }
        }
        &:nth-child(2) {
            width: 362px;
            margin-left: 10px;
            margin-right: 10px;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        &:last-child {
            width: 752px;
            position: relative;
            padding: 30px;
            .top p {
                color: #FFFFFF;
                font-size: 18px;
                font-weight: 300;
                letter-spacing: 2px;
                line-height: 24px;
                margin: 0;
                text-align: right;
            }
            .bottom {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 30px;
                padding-top: 0px;
                h2{
                    font-family: "Kommissar Web";
                    color: #FFFFFF;
                    font-size: 59px;
                    font-weight: 500;
                    letter-spacing: 2.5px;
                    line-height: 50px;
                    text-transform: uppercase;
                }
                p {
                    color: #FFFFFF;
                    font-size: 12px;
                    font-weight: 500;
                    letter-spacing: 0;
                    line-height: 12px;
                    text-transform: uppercase;
                }
            }
            .bottom-box {
                width: 45%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-wrap: wrap;
                margin-top: 20px;
            }
            .bottom-box > div {
                box-sizing: border-box;
                border: 1px solid #FFFFFF;
                color: #FFFFFF;
                font-family: Theinhardt;
                font-size: 13.85px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 20px;
                text-align: left;
                height: 18.46px;
                text-transform: uppercase;
                &:first-child {
                    width: 100%;
                    margin-bottom: 3px;
                    padding: 0px 1px;
                }
                &:nth-child(2) {
                    display: flex;
                    width: 85%;
                    .arrow {
                        width: 33.85px;
                        background: #FFFFFF;
                        height: 100%;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        img {
                            width: 80%;
                        }
                    }
                    .copy {
                        width: 100%;
                        padding: 0px 2px;
                    }
                }
                &:last-child {
                    width: 13%;
                    text-align: center;
                }
            }
        }
        @include respond-to("iphone6-plus") {
            margin-bottom: 30px;
            &:first-child {
                width: 100%;
                height: 68px;
                p{
                    writing-mode: horizontal-tb;
                    transform: none;
                }
            }
            &:nth-child(2) {
                width: 100%;
                margin-left: 0;
                margin-right: 0;
            }
            &:last-child {
                width: 100%;
                .bottom h2 {
                    font-size: 53px;
                }
                .bottom-box {
                    width: 100%;
                    > div {
                    font-size: 13px;
                    }
                }
            }
        }
    }
}
.shop-lists {
    background-color: $rsr-primary;
    padding-bottom: 180px;
    .items-container {
        width: 100%;
        display: flex;
        align-items: flex-start;
        // justify-content: space-between;
        flex-wrap: wrap;
    }
    .shop-item {
        box-sizing: border-box;
        height: 322px;
        width: 370px;
        border: 1px solid #FFFFFF;
        position: relative;
        padding: 30px;
        margin-bottom: 30px;
        margin-right: 29px;
        transition: 1s;
        &:nth-child(3n) { 
            margin-right: 0 !important;
        }
        .top p {
            color: #FFFFFF;
            font-size: 18px;
            font-weight: 300;
            letter-spacing: 2px;
            line-height: 24px;
            margin: 0;
            text-align: right;
        }
        .bottom {
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 30px;
            padding-top: 0px;
            transition: 0s;
            h2{
                font-family: "Kommissar Web";
                color: #FFFFFF;
                font-size: 59px;
                font-weight: 500;
                letter-spacing: 2.5px;
                line-height: 50px;
                text-transform: uppercase;
            }
            p {
                color: #FFFFFF;
                font-size: 12px;
                font-weight: 500;
                letter-spacing: 0;
                line-height: 12px;
                text-transform: uppercase;
            }
        }
        .bottom-box {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            margin-top: 20px;
        }
        .bottom-box > div {
            box-sizing: border-box;
            border: 1px solid #FFFFFF;
            color: #FFFFFF;
            font-family: Theinhardt;
            font-size: 13.85px;
            font-weight: 500;
            letter-spacing: 0;
            line-height: 20px;
            text-align: left;
            height: 18.46px;
            text-transform: uppercase;
            &:first-child {
                width: 100%;
                margin-bottom: 3px;
                padding-left: 1px;
            }
            &:nth-child(2) {
                display: flex;
                width: 85%;
                .arrow {
                    width: 33.85px;
                    background: #FFFFFF;
                    height: 100%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img {
                        width: 80%;
                    }
                }
                .copy {
                    width: 100%;
                    padding: 0px 2px;
                }
            }
            &:last-child {
                width: 13%;
                text-align: center;
            }
        }
        .btn {
            visibility: hidden;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            padding: 30px;
            padding-top: 0px;
            width: 100%;
            transition: 0s;
            a {
                box-sizing: border-box;
                height: 40px;
                border: 2px solid #181818;
                width: 100%;
                padding-left: 14px;
                padding-right: 30px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                color: #181818;
                font-size: 14px;
                font-weight: 500;
                img {
                    height: 8px;
                    width: 28px;
                }
            }
        }
        &:hover {
            background: $rsr-secondary;
            h2, p {
                color: #181818;
            }
            .bottom-box {
                visibility: hidden;
            }
            .btn {
                visibility: visible;
            }
        }
        &.active {
            background: $rsr-secondary;
             h2, p {
                color: #181818;
            }
            .bottom-box {
                visibility: hidden;
            }
            .btn {
                visibility: visible;
            }
            .btn a {
                border: 2px solid #181818;
                color: #181818;
                img {
                    content:url("/img/icons/buttons/button-spear-black2.png");
                }
            }
        }
        @include respond-to("iphone6-plus") {
            width: 100%;
            margin-right: 0;
            // background: $rsr-secondary;
            h2, p {
                color: #181818;
            }
            .bottom h2 {
                font-size: 53px;
            }
            .bottom-box {
                visibility: hidden;
            }
            .btn {
                visibility: visible;
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 30px;
                padding-top: 0px;
                width: 100%;
                transition: 0s;
                a {
                    box-sizing: border-box;
                    height: 40px;
                    border: 2px solid $rsr-secondary;
                    width: 100%;
                    padding-left: 14px;
                    padding-right: 30px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    color: $rsr-secondary;
                    font-size: 14px;
                    font-weight: 500;
                    img {
                        content:url("/img/icons/buttons/button-spear.svg");
                        height: 8px;
                        width: 28px;
                    }
                }
            }
        }
    }
}