////
/// Contact
////
.contact-header {
    //Dark Background
    background-color: $rsr-primary;
    text-align: left;
    min-height: calc(100vh - 145px);
    .content__container {
        @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

        @include respond-to("iphone6-plus") {
            @include column(1);
        }

        min-height: calc(100vh - 145px);
        text-align: left;
        // padding-bottom: toem(200px);
        display: flex;
        align-items: center;
        justify-content: start;

        h4 { color: $rsr-secondary; }
        h6 {
            color: $rsr-secondary;
            margin-top: torem(10px);
            margin-bottom: torem(10px);
        }

        .content__copy {
            // max-width: toem(500px);
            h3 {
                color: $rsr-secondary;
                margin-top: toem(34px);
                margin-bottom: toem(50px);
            }
        }

        .content__copy__personalization {
            max-width: toem(480px);
            p {
                color: $rsr-octonary;
                margin-bottom: toem(40px);
                font-size: toem(16px);
            }
        }

        .cta {
            text-align: left;
            a {
                border-style: solid;
                border-width: toem(2px);
                border-color: $rsr-secondary;
                img {
                    display: inline;
                    margin-left: toem(19px);
                }
            }
        }
    }
}