////
/// Home
////

$rsr-registration-header-border-bottom: #d7d7d7;
$rsr-message-error: #f10000;

#sidebar-home {
  width: 190px;
  position: fixed;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 2;
  }

  li {
    line-height: 1.85;
  }

  a {

    color: $rsr-secondary;
    font-size: toem(12px);
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:hover {
      color: $rsr-senary;
    }
    &.active {
      font-weight: 1000;
    }
  }
}

.wrapper {
   @include center($rsr-wrapper-min-width);

   padding-right: torem(16px);
   padding-left: torem(16px);
   &__small {
     @include center(toem(1072px));

     padding-right: torem(16px);
     padding-left: torem(16px);
   }
 }

.header-wrapper {
  @include center($rsr-wrapper-header-min-width);

  padding-right: torem(16px);
  padding-left: torem(16px);
  &__small {
    @include center(toem(1072px));

    padding-right: torem(16px);
    padding-left: torem(16px);
  }
}

.home-hero {
  text-align: left;

  //Apply Dark Background
  background: rgba($rsr-primary, 1);

  //Re-apply color after it has been switched
  h1, h2, h3, h6, p, a {  color: $rsr-secondary;  }

  h6 {
    letter-spacing: 0.2em;
    margin-bottom: toem(12px);
  }

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    //Hero strikethrough animation
    //--------------------------------
    //--------------------------------
    //span {
    //  position: relative;
    //  display: block;
    //  cursor: pointer;
    //}
    //
    //span:before, span:after {
    //  content: '';
    //  position: absolute;
    //  width: 0%;
    //  height: toem(10px);
    //  top: 50%;
    //  margin-top: toem(-5px);
    //  margin-left: toem(-5px);
    //  background: $rsr-secondary;
    //}
    //
    //span:before {
    //  left: -2.5px;
    //}
    //span:after {
    //  right: 2.5px;
    //  background: $rsr-secondary;
    //  transition: width 0.8s cubic-bezier(0.22, 0.61, 0.36, 1);
    //}
    //
    //span:hover:before {
    //  background: $rsr-secondary;
    //  width: 100%;
    //  transition: width 0.5s cubic-bezier(0.22, 0.61, 0.36, 1);
    //}
    //
    //span:hover:after {
    //  background: transparent;
    //  width: 100%;
    //  transition: 0s;
    //}
    //--------------------------------
    //--------------------------------

    .content__copy {
      margin-top: toem(50px);

      //Add Padding after removing signature on mobile
      @include respond-to("iphone6-plus") {
        padding-bottom: toem(100px);
      }
    }

    .content__image {

      //Hide on mobile
      @include respond-to("iphone6-plus") {
        display: none;
      }

      margin-top: toem(80px);
      padding-bottom: toem(100px);
      text-align: left;

      a {
        padding-left: 0;
      }
    }
  }
}

//Dark Background
.home-services {

  text-align: left;
  padding-top: toem(120px);

  //Apply Dark Background
  background: rgba($rsr-primary, 1);

  //Re-apply color after it has been switched
  h1, h2, h3, h4, h6, ul, li, p, a {  color: $rsr-secondary;  }

  h6 {
    letter-spacing: 0.2em;
    margin-bottom: toem(12px);
  }

  .wrapper {

    //Title Portion of the section
    .content__container__title {
      padding-bottom: toem(100px);

      .content__title {
        @include column(1/2, $gutter: 0, $cycle: 2);
        @include respond-to("iphone6-plus") {
          @include column(1);
        }
      }

      //Hide image at the top
      .content__image__mobile {
        display: none;
      }

      @include respond-to("iphone6-plus") {
        padding-top: 0;

        .content__image__mobile {
          //Reveal image on mobile
          @include column(1, $cycle: 1);
          margin-top: toem(-100px);
          padding-bottom: toem(50px);
          display: block;
        }
      }

      .content__image {

        margin-top: toem(-50px);
        padding-bottom: toem(100px);
        max-width: toem(500px);


        @include respond-to("iphone6-plus") {
          padding-top: toem(50px);
          padding-bottom: toem(100px);
        }

        @include column(1/2, $gutter: 0, $cycle: 2);
        @include respond-to("iphone6-plus") {
          display: none;
          @include column(1, $cycle: 1);
        }
      }
    }


    //List Portion of the section
    .content__container__services {
      padding-bottom: toem(180px);

      @include respond-to("iphone6-plus") {
        padding-top: toem(50px);
        padding-bottom: toem(100px);
      }

      @include column(1/2, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }

      h6 {
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
      }

      .content__copy {
        max-width: toem(440px);
        p {
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .specialties {
        text-align: left;
        //padding-top: toem(25px);
        padding-bottom: toem(50px);

        ul {
          margin: 0;
          padding-left: toem(15px);
          //list-style-type: none;
          //line-height: 2;
        }

        li {
          line-height: 0.85;
          padding-left: toem(10px);
        }

        h3 {
          font-size: toem(16px);
          font-weight: 400;
          line-height: 0.8;
        }

        @include respond-to("iphone6-plus") {
          h3 {
            font-size: toem(16px);
            font-weight: 600;
            line-height: 0.8;
          }
        }

      }

      .cta {
        a {
          border-style: solid;
          border-width: toem(2px);
          border-color: $rsr-secondary;

          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
      }
    }
  }
}

//Light Background
.home-thoughts {
  .wrapper {
    .content__image {
      img {
        margin-top: 0px;
        @include column(1/2, $cycle: 2);
        @include respond-to("iphone6-plus") {
          @include column(1, $cycle: 1);
        }

        @include respond-to("iphone6-plus") {
          margin-bottom: 0px;
        }

      }
    }

    .content__container {
      padding-top: toem(200px);
      padding-bottom: toem(200px);

      //Reducing padding for mobile
      @include respond-to("iphone6-plus") {
        padding-top: toem(50px);
        padding-bottom: toem(100px);
      }

      @include column(1/2, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }

      h6 {
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
      }

      .content__copy {
        max-width: toem(440px);
        p {
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .cta {
        text-align: left;
        margin-bottom: 80px;
        &:last-child {
          margin-bottom: 0;
        }
        a {
          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }
    }


  }
}

//Dark Background
.home-ea {
  background-color: $rsr-primary;

  .wrapper {
    .content__container {
      padding-top: toem(250px);
      padding-bottom: toem(250px);

      //Hide image at the top
      .content__image__mobile {
        display: none;
      }

      @include respond-to("iphone6-plus") {
        padding-top: 0;
        padding-bottom: toem(100px);

        //Unhide image at the top
        .content__image__mobile {
          //Reveal image on mobile
          @include column(1, $cycle: 1);
          padding-top: toem(20px);
          padding-bottom: toem(50px);
          display: block;
        }
      }

      @include column(1/2, $gutter: 0, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }

      h4 { color: $rsr-secondary; }
      h6 {
        color: $rsr-secondary;
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
      }

      .content__copy {
        max-width: toem(420px);
        p {
          color: $rsr-secondary;
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .cta {
        text-align: left;
        a {
          border-style: solid;
          border-width: toem(2px);
          border-color: $rsr-secondary;
          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          color: $rsr-secondary;
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }
    }

    .content__image {
      padding-top: toem(200px);
      padding-bottom: toem(200px);

      //Hide image on mobile
      @include respond-to("iphone6-plus") {
        display: none;
      }

      @include column(1/2, $gutter: 0, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }
    }

  }
}

//Light Background
.home-logos {
  background: #F5F5F5;
  .wrapper {
    .content__container {
      padding-top: toem(180px);
      padding-bottom: toem(180px);
      max-width: toem(420px);

      @include respond-to("iphone6-plus") {
        padding-top: toem(100px);
        padding-bottom: toem(50px);
      }

      @include column(1/2, $gutter: 0, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }

      .content__copy {
        p {
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .cta {
        text-align: left;
        a {
          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }
    }


    .content__image {
      padding-top: toem(180px);
      padding-bottom: toem(180px);
      display: flex;
      flex-wrap: wrap;

      @include respond-to("iphone6-plus") {
        padding-top: toem(50px);
        padding-bottom: toem(100px);
      }

      @include column(1/2, $gutter: 0, $cycle: 2);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
      }

      .img-container {
        background: $rsr-secondary;
        width: 30%;
        height: 180px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 29px;
        margin-bottom: 29px;
        &:nth-child(3n) { 
            margin-right: 0 !important;
        }

        @include respond-to("iphone6-plus") {
          height: 105px;
          margin-right: 17px;
          margin-bottom: 17px;
        }

        img {
          width: 65%;
        }
      }
    }

  }
}

//Dark Background
.home-change {
  background-color: $rsr-primary;
  min-height: 100vh;

  .wrapper {
    .content__container {
      padding-top: toem(180px);
      padding-bottom: toem(180px);

      @include column(1);

      h4 {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        color: $rsr-secondary;
      }

      .content__copy {
        margin-left: auto;
        margin-right: auto;
        text-align: center;
        max-width: toem(600px);
        p {
          color: $rsr-secondary;
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .cta {
        a {
          border-style: solid;
          border-width: toem(2px);
          border-color: $rsr-secondary;
          margin-left: auto;
          margin-right: auto;

          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          color: $rsr-secondary;
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }
    }
  }
}

@include c-modal();

.register__widget {
  border-radius: 2px;

  //Transluscent Background
  background: rgba(#000000, .9);

  .c-modal__content {
    @include respond-to("ipad-portrait") {
      width: 80%;
    }

    width: 50%;
    height: auto;

    background: rgba($rsr-primary, 1);
    box-shadow: 0 0px 1px 0 #ffff;
    // box-shadow: 0 15px 45px 0 rgba(0,0,0,.1);
  }
  .c-modal__close {
    top: 0;
    right: 0;

    font-size: toem(18px);
    font-weight: bold;

    margin: toem(4px) toem(10px);
    padding: toem(4px);

    cursor: pointer;

    color: rgba($rsr-secondary, .3);
  }
  &__header {
    height: toem(40px);

    border-bottom: 1px solid $rsr-registration-header-border-bottom;
    ul {
      margin: 0;
      padding: 0;

      list-style-type: none;
      height: 100%;
      li {
        display: inline-block;
        float: left;

        position: relative;

        font-size: toem(12px);
        font-weight: 700;

        padding: torem(12px) torem(20px) torem(11px);

        text-transform: uppercase;
        height: 100%;

        color: rgba($rsr-secondary, .3);
        &:last-of-type {
          &:after {
            content: initial;
          }
        }
        &.step--password {
          color: $rsr-secondary;
          border-bottom: 2px solid $rsr-secondary;
        }
        &:after {
          position: absolute;
          top: 50%;
          right: -8%;

          width: 0;
          height: 0;

          content: " ";
          transform: translate(0, -50%);

          border-top: 5px solid transparent;
          border-bottom: 5px solid transparent;
          border-left: 5px solid rgba($rsr-secondary, .3);
        }
      }
    }
  }
  &__form {
    position: relative;

    padding: toem(25px) toem(25px) toem(35px) toem(25px);
    input {
      background: rgba($rsr-primary, 1);

      @include placeholder {
        color: rgba($rsr-secondary, .5);
      }

      display: inline-block;

      font-size: toem(22px);
      font-weight: 400;

      width: 100%;
      padding: 4px;

      //Slight offset
      margin-top: toem(5px);

      color: $rsr-secondary;
      border: none;
      outline: none;

      -webkit-appearance: none;
      &:nth-child(1) {
        left: 0;
      }
      &:nth-child(2) {
        left: 100%;
      }
      &:nth-child(3) {
        left: 100%;
      }
      &.current--active {
        left: 0;
      }
    }
    .action__text {
      position: absolute;
      right: toem(20px);
      bottom: toem(18px);

      font: $rsr-font;
      font-family: $rsr-font-family;
      font-size: toem(12px);
      font-weight: 700;
      line-height: 1.4;

      color: rgba($rsr-secondary, .4);
      &.active {
        color: $rsr-quinary;
      }
    }
  }
  &.active--name {
    ul {
      .step--name {
        color: $rsr-secondary;
        border-bottom: 2px solid $rsr-secondary;
      }
    }
  }
  &.active--company {
    ul {
      .step--name {
        color: $rsr-secondary;
        border-bottom: 2px solid $rsr-secondary;
      }
      .step--company {
        color: $rsr-secondary;
        border-bottom: 2px solid $rsr-secondary;
      }
    }
  }
  &.active--email {
    ul {
      .step--name,
      .step--company {
        color: $rsr-secondary;
        border-bottom: 2px solid $rsr-secondary;
      }
      .step--email {
        color: $rsr-secondary;
        border-bottom: 2px solid $rsr-secondary;
      }
    }
  }
  &.has-error {
    .register__widget__form {
      input {
        color: $rsr-message-error;
      }
    }
    .action__text {
      color: $rsr-message-error;
    }
  }
}
