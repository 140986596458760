////
/// Buttons
/// @group Component - Buttons
////
$rsr-primary--hover: $rsr-primary;
$rsr-primary--focus: $rsr-primary;
$rsr-white--hover: #ffe5d9;
$rsr-white--focus: #ffccb3;

.button {
  display: inline-block;

  font-size: toem(16px);
  font-weight: 700;
  font-size: torem(13px);
  line-height: 1;
  padding: toem(30px) toem(45px);

  transition: all $rsr-transition-duration $rsr-transition-timing;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;
  text-transform: uppercase;

  color: $rsr-white;
  border: none;
  outline: none;
  background-color: $rsr-primary;

  -webkit-appearance: none;
  &:hover {
    background-color: $rsr-primary--hover;
  }
  &:focus {
    background-color: $rsr-primary--focus;
  }
  &--shadow {
    box-shadow: 4px 4px 0 0 rgba($rsr-primary, .20);
    &:hover {
      box-shadow: 4px 4px 0 0 rgba($rsr-primary--hover, .20);
    }
  }
  &__secondary {
    color: $rsr-primary;
    background: $rsr-white;
    &:hover {
      background-color: $rsr-white--hover;
    }
    &:focus {
      background-color: $rsr-white--focus;
    }
  }
}
.action__text {
  display: inline-block;

  position: relative;

  font-family: $rsr-font-family-title;
  font-size: torem(13px);
  font-weight: 700;
  line-height: 1;

  padding: 0 0 toem(3px) 0;

  transition: all $rsr-transition-duration $rsr-transition-timing;
  text-align: center;
  vertical-align: middle;
  text-decoration: none;

  color: $rsr-primary;
  border: none;
  background-color: transparent;

  -webkit-appearance: none;
  &:before {
    position: absolute;
    bottom: 0;

    width: 100%;
    height: 4px;

    content: '';

    background: $rsr-primary;
  }
  &:hover {
    color: $rsr-primary--hover;
    &:before {
      background: $rsr-primary--hover;
    }
  }
  &:focus {
    color: $rsr-primary--focus;
    &:before {
      background: $rsr-primary--focus;
    }
  }
  &--gray {
    color: $rsr-septenary;
    &:before {
      background: $rsr-septenary;
    }
    &:hover {
      color: shade($rsr-septenary, 15%);
      &:before {
        background: shade($rsr-septenary, 15%);
      }
    }
    &:focus {
      color: $rsr-primary--focus;
      &:before {
        background: $rsr-primary--focus;
      }
    }
  }
}
