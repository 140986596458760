////
/// Animations
/// -> Keyframes and every other general animation to be applied
/// @group Animations
////

///
@keyframes fade-in {
  from {
    visibility: hidden;

    opacity: 0;
  }

  to {
    visibility: visible;

    opacity: 1;
  }
}
///
@keyframes fade-out {
  from {
    visibility: visible;

    opacity: 1;
  }

  to {
    visibility: hidden;

    opacity: 0;
  }
}
///
@keyframes slide-down {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}
///
@keyframes slide-up {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(-100%);
  }
}

@keyframes overlay-blue-anim {
  0% {
    transform: translate(100%, 0) rotate(-15deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes overlay-blue-anim--reverse {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(120%, 0) rotate(-15deg);
  }
}

@keyframes overlay-orange-anim {
  0% {
    transform: translate(100%, 0) rotate(-10deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}

@keyframes overlay-orange-anim--reverse {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  100% {
    transform: translate(120%, 0) rotate(-10deg);
  }
}
