////
/// Header
////

header {
  z-index: 100;
  width: 100%;

  .header__spacer {
    @include respond-to("iphone6-plus") {
      height: 35px;
    }
    height: 32px;
  }

  &.light {
    //Light background
    background-color: #F5F5F5;

    #main-header {
      background-color: rgba($rsr-secondary, 0);
    }

    .header__logo {
      background-image: url("/img/branding/skylarroebuck-logo-black.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    a, p {
      color: rgba($rsr-primary, 1);
    }
    li {
      &.active {
        background-color: $rsr-primary;
        a {
          color: $rsr-secondary;
        }

        //Different hover text color when active.
        &:hover {
          a {
            color: $rsr-secondary;
          }
        }
      }
    }
  }

  &.dark {
    //Dark background
    background-color: rgba($rsr-primary, 1);

    #main-header {
      background-color: rgba($rsr-primary, 0);
    }

    .header__logo {
      background-image: url("/img/branding/skylarroebuck-logo-white.svg");
      background-repeat: no-repeat;
      background-size: cover;
    }
    a, p {
      color: rgba($rsr-secondary, 1);
    }
    li {
      &.active {
        background-color: $rsr-secondary;
        a {
          color: $rsr-primary;
        }

        //Remove the background color on mobile
        @include respond-to("iphone6-plus") {
          background-color: $rsr-primary;
          a {
            color: $rsr-secondary;
          }
        }

        //Different hover text color when active
        &:hover {
          a {
            color: $rsr-primary;
          }
        }
      }
    }
  }

  &.custom {
    position: absolute;
    top: 0;
    background: none !important;
  }

  #main-header {

    @include respond-to("iphone5") {
      padding-bottom: toem(40px);
    }

    padding-bottom: toem(65px);
    transition: all $rsr-transition-duration $rsr-transition-timing;


    .header__logo {
      height: toem(43px);
      width: toem(86px);
      background-size: contain;
    }

    &.fixed {
      @include respond-to("iphone5") {
        padding: toem(5px);
      }

      padding: toem(16px);

      .header__logo {
        img {
          @include respond-to("iphone6-plus") {
            max-width: 100%;
          }
          @include respond-to("iphone6") {
            width: 100%;
          }

          max-width: 100%;
          padding-top: 2%;
        }
      }
    }
  }
  .header__logo {
    @include respond-to("iphone6-plus") {
      width: 43%;
      margin-top: toem(6px);
      padding-top: toem(8px);
    }
    @include respond-to("iphone6") {
      width: 42%;
      margin-top: toem(6px);
      padding-top: toem(11px);
    }
    @include respond-to("iphone5") {
      width: 35%;
      margin-top: toem(6px);
      padding-top: toem(14px);
    }

    float: left;
    img {
      @include respond-to("iphone6-plus") {
        max-width: 75%;
      }
      @include respond-to("iphone6") {
        width: 100%;
      }

      transition: all $rsr-transition-duration $rsr-transition-timing;
    }
  }
  .header__nav {
    float: right;

    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
      line-height: 3;

      @include respond-to("iphone6-plus") {
        margin-top: toem(6px);
      }

      li {
        display: inline-block;
        line-height: 2;
        vertical-align: middle;
        padding: 0 toem(16px);

        &.mobile {
          @include respond-to("iphone6-plus") {
            display: inline-block;
          }

          display: none;

          line-height: 1;
          svg {
            max-width: 30px;
            margin-top: 7px;
          }
        }
        &:not(.mobile) {
          @include respond-to("iphone6-plus") {
            display: none;
          }
        }
        &:last-child {
          padding-right: 0;
        }
        a, p {
          font-weight: 700;
          font-size: toem(12px);
          text-transform: uppercase;
          letter-spacing: 0.04em;

          //color:();
        }
        //header.dark a, p{
        //  color: $rsr-primary;
        //}

        a {
          &:hover {
            color: $rsr-senary;
          }
        }

        @include respond-to("iphone6-plus") {
          a {
            font-size: toem(18px);
          }
        }
      }
    }
  }
}

.nav__mobile {
  &.c-modal {
    background-color: $rsr-primary;
    .c-modal__close {
      left: 20px;
    }
    .modal__logo {
      text-align: center;
      svg {
        max-width: 40px;
      }
    }
    .c-modal__content {
      width: 85%;
      margin-top: -40%;

      background-color: transparent;
    }
    .modal__nav {
      text-align: center;
      ul {
        margin: 10vh 0;
        padding: 0;

        list-style: none;
        li {
          white-space: nowrap;
        }
        a {
          font-size: 6vh;
          font-weight: 700;
          line-height: 9vh;

          text-transform: uppercase;

          color: $rsr-white;
        }
      }
    }
    &.c-modal--is-visible {
      .c-modal__content {
        margin-top: -8%;
      }
    }
  }
  .footer__social {
    padding: 0;

    text-align: center;
    ul {
      padding: 0;
      li {
        margin: 0 toem(10px);
      }
    }
  }
}
