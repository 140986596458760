////
/// Base File
/// Init css basic settings
/// @group Base
////


@import './typography';

*,
*:before,
*:after {
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body,
html {
  font: $rsr-font;

  color: $rsr-font-color;
  @include respond-to('ismobile') {
    font-size: 95%;
  }
}

body {
  background: $rsr-background;
  overflow-x: hidden; 
}

img {
  display: inline-block;

  max-width: 100%;
  height: auto;

  outline: 0;
}

fieldset {
  margin: 0;
  padding: 0;

  border: none;
}

blockquote {
  margin: 0;
}

menu,
nav {
  ul,
  li {
    margin: 0;
    padding: 0;
  }
}

hr {
  width: 100%;
  height: 20px;
  margin: 0 0 20px;

  border: none;
  border-bottom: 1px dashed;
}

button {
  &:focus {
    outline: none;
  }
}

a {
  transition: all $rsr-transition-duration $rsr-transition-timing;
  text-decoration: none;
}
