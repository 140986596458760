////
/// Thoughts
////
.thoughts-featured {
  text-align: left;

  //Apply Dark Background
  background: $rsr-primary;

  //Re-apply color after it has been switched
  h1, h2, h3, h6, p, a {  color: $rsr-secondary;  }

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);
    padding-top: toem(30px);
    padding-bottom: toem(160px);
    @include respond-to("iphone6-plus") {
      @include column(1);
      padding-bottom: toem(100px);
    }
    .content__copy {
      margin-top: toem(40px);
    }

    h4 {
      color: $rsr-secondary;
    }
  }
}
.thoughts-list {
  background: #F5F5F5;
  .wrapper {
    display: flex;
    padding-top: toem(120px);

    //Reducing padding for mobile
    @include respond-to("iphone6-plus") {
      padding-top: toem(50px);
      padding-right: 0px;
      display: block;
    }

    .nav-dates {
      position: relative;
      width: 10%;
      
      ul {
          position: -webkit-sticky;
          position: sticky;
          top: 0;
          padding-top: toem(200px);
          padding-left: 0;
          list-style: none;
      }
      li {
        height: 30px;
        display: flex;
        align-items: center;
      }
      li a {
        color: $rsr-primary;
        font-size: 12px;
      }
      li.active a {
        font-weight: bold;
        img {
          margin-bottom: 3px;
          margin-right: 5px;
        }
      }
      @include respond-to("iphone6-plus") {
        width: 100%;
        overflow: scroll;
        max-width: 340px;
        margin-bottom: 10px;
        ul {
          display: inline-flex;
          padding-top: 0;
        }
        li {
          margin-right: 20px;
          white-space: nowrap;
          &.active a {
            width: 60px;
          }
        }
      }
    }
    
    .contents__container {
      flex: 1;
    }

    .content__container {
      margin-bottom: 120px;
      position: relative;
      min-height: 830px;

      .placement-container {
        position: absolute;
        right: -18vw;
        top: 0;
      }

      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
        // padding-top: toem(50px);
        // padding-bottom: toem(100px);
        min-height: 0px;
        margin-bottom: 100px;
        .placement-container {
          position: static;
        }
      }

      .content__header {
        position: relative;
        max-height: 480px;
        img {
          object-fit: cover;
          max-height: 480px;
          width: 100%;
        }
      }

      .content__title {
        position: absolute;
        left: 60px;
        bottom: 60px;
        max-width: 600px;
      }

      h6 {
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
        color: $rsr-secondary;
      }

      h4 {
        color: $rsr-secondary;
      }

      .content__copy {
        padding-left: toem(50px);
        max-width: toem(500px);
        p {
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }

      .cta {
        padding-left: toem(50px);
        text-align: left;
        a {
          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }

      @include respond-to("iphone6-plus") {
        .content__header img {
          max-height: none;
          height: 160px;
        }
        .content__title {
          left: 15px;
          bottom: 15px;
        }
        h4 {
          font-size: 30px;
        }
        .content__copy {
          padding-left: toem(30px);
          max-width: toem(300px);
          p {
            margin-bottom: 10px;
          }
        }
        .cta {
          padding-left: toem(30px);
          .button {
            display: table-cell;
          }
          p {
            display: table-caption;
          }
        }
      }
    }
  }
}
.thoughts-abstract {
  background: #F5F5F5;
  
  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);
    @include respond-to("iphone6-plus") {
      @include column(1);
    }
    padding-bottom: toem(120px);
    .content__copy {
      margin-top: toem(40px);
      p {
        margin-bottom: 0;
      }
    }
  }
}
.page-break {
  background: #F5F5F5;
  padding-bottom: toem(120px);
}
.thoughts-details {
  background: #F5F5F5;
  padding-bottom: toem(120px);
  .content__container {
    width: 100%;
    img {
      margin-bottom: 100px;
    }
    .content__left {
      @include column(1/6);
      p {
        color: #181818;
        font-family: Theinhardt;
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
        line-height: 14px;
      }
       @include respond-to("iphone6-plus") {
        width: 100%;
        margin-right: 0;
        display: flex;
        p {
          margin-right: 10px;
        }
      }
    }
    .content__right {
      @include column(4/6);
      @include respond-to("iphone6-plus") {
        width: 100%;
      }
      h2 {
        font-weight: 900;
      }
      p {
        margin-bottom: 0;
      }
    }
  }
}