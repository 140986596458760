////
/// Hackrva Detail Page
////

/// Section Entrepreneur
.venture-hackrva--bg {

  //Fix the header spacer issue for detail pages
  margin-top: toem(-178px);
  padding: toem(88px) 0;

  background: url('../img/ventures/hackrva/hackrva-hero.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;

  @include respond-to("iphone6-plus") {
    background: url('../img/ventures/hackrva/hackrva-hero-mobile.png');
    background-position: center bottom;
  }


  .wrapper {
    .content__container {
      padding-top: toem(350px);
      h4 { color: $rsr-secondary; }
      h6 {
        color: $rsr-secondary;
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
      }
    }
  }
}

/// Section Entrepreneur
.venture-hackrva-abstract {
  text-align: left;
  padding-top: 150px;
  padding-bottom: 150px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }
    img {
      max-width: toem(150px);
    }
  }
  .content__container {
    @include column(4/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }
    h3 {  margin: 0; }
  }
}

/// Section The Opportunity
.venture-hackrva-opportunity {
  text-align: left;
  padding-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }

    .content__articles {
      padding-bottom: toem(50px);

      .article {
        padding-bottom: toem(200px);
        .image-left {
          @include column(1/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-bottom: toem(25px);
          }
        }

        .description-right {
          padding-top: toem(10px);
          padding-left: toem(50px);
          @include column(4/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-top: toem(0px);
            padding-left: toem(0px);
            padding-bottom: toem(75px);
          }
          h3 {
            margin: 0;
            padding: 0;
            padding-bottom: toem(7px);
            font-weight: 800;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.08em;
            font-size: toem(12px);
            padding-bottom: toem(10px);
          }
        }
      }
    }
  }
}

/// Side-by-side images
.venture-hackrva-two {
  margin-top: toem(25px);

  .content__image__two {
    img {
      padding-bottom: toem(50px);
      @include column(1/2, $cycle: 2);

      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
        //display: none;
      }
    }
  }
}

/// Section The Solution
.venture-hackrva-solution {
  text-align: left;
  padding-top: 150px;
  padding-bottom: 50px;

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }

    .content__articles {
      padding-bottom: toem(50px);

      .article {
        padding-bottom: toem(200px);
        .image-left {
          @include column(1/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-bottom: toem(25px);
          }
        }

        .description-right {
          padding-top: toem(10px);
          padding-left: toem(50px);
          @include column(4/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-top: toem(0px);
            padding-left: toem(0px);
            padding-bottom: toem(75px);
          }
          h3 {
            margin: 0;
            padding: 0;
            padding-bottom: toem(7px);
            font-weight: 800;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.08em;
            font-size: toem(12px);
            padding-bottom: toem(10px);
          }
        }
      }
    }
  }
}

/// Full image rendering
.venture-hackrva-full {
  .content__image__full {
    img {
      @include column(1, $cycle: 1);
    }
  }
}

/// Seperator1
.venture-hackrva-seperator1 {
  padding: toem(300px) 0;
  background: url('../img/ventures/hackrva/fullwidth.jpg');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include respond-to("iphone6-plus") {
    background: url('../img/ventures/hackrva/fullwidth-mobile.png');
    background-position: center bottom;
  }
}

/// Section The Story
.venture-hackrva-story {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

/// Section The Story - no sidebar -
.venture-hackrva-story-nosidebar {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

/// Seperator2
.venture-hackrva-seperator2 {
  padding: toem(300px) 0;
  background: url('../img/ventures/hackrva/fullwidth2.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  @include respond-to("iphone6-plus") {
    background: url('../img/ventures/hackrva/fullwidth2-mobile.png');
    background-position: left bottom;
  }
}

/// Section The Venture
.venture-hackrva-venture {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

/// Section The Venture - no sidebar
.venture-hackrva-venture-nosidebar {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

//Thoughts Footer
.thoughts-footer {
  background: $rsr-primary;
  padding-top: toem(100px);
  padding-bottom: toem(100px);

  .content__container {

    .content__image__full {
      img {
        @include column(1, $cycle: 1);
      }

      @include respond-to("iphone6-plus") {
        display: none;
      }
    }
    .content__image__full__mobile {
      display: none;
      img {
        @include column(1, $cycle: 1);
      }

      @include respond-to("iphone6-plus") {
        display: block;
      }
    }
  }
}