@font-face {
  font-family: "icon-font";
  src: url('../fonts/icon-font.eot');
  src: url('../fonts/icon-font.eot?#iefix') format('eot'),
    url('../fonts/icon-font.woff2') format('woff2'),
    url('../fonts/icon-font.woff') format('woff'),
    url('../fonts/icon-font.ttf') format('truetype'),
    url('../fonts/icon-font.svg#icon-font') format('svg');
}

.ms-icon {
  font-family:"icon-font";
  display:inline-block;
  vertical-align:middle;
  line-height:1;
  font-weight:normal;
  font-style:normal;
  speak:none;
  text-decoration:inherit;
  text-transform:none;
  text-rendering:optimizeLegibility;
  -webkit-font-smoothing:antialiased;
  -moz-osx-font-smoothing:grayscale;
}


  .icon-star-full:before {
    content: "\E001";
  }

  .icon-star-half:before {
    content: "\E002";
  }

  .icon-star-quarter:before {
    content: "\E003";
  }

  .icon-star-three-quarters:before {
    content: "\E004";
  }
