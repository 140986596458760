////
/// About
////

#sidebar {
  width: 190px;
  position: fixed;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    line-height: 2;
  }

  li {
    line-height: 1.85;
  }

  a {

    color: $rsr-primary;
    font-size: toem(12px);;
    font-weight: 500;
    line-height: 1;
    letter-spacing: 0.08em;
    text-transform: uppercase;

    &:hover {
      color: $rsr-senary;
    }
    &.active {
      font-weight: 1000;
    }
  }
}
.about-abstract {
  text-align: left;
  background: #F5F5F5;
  h1 {
    //Unique change for fixing title
    line-height: 0.8;
    padding-bottom: 15px;
  }

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    text-align: left;

    .content__copy {
      margin-top: 55px;
      margin-bottom: 120px;
    }

    h2 {
       font-weight: 900;
    }
  }
}
.about-entrepreneur--bg {
  padding: toem(88px) 0;
  background: url('../img/about/bg.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;

  .wrapper {
    padding-bottom: toem(300px);

    .content__container {
      @include column(4/6, $offset: 1/6);

      @include respond-to("iphone6-plus") {
        @include column(1);
      }

      .about-item {
        @include column(2/4, $cycle: 2);
        margin-bottom: toem(80px);

        h3 {
          font-weight: 600;
          color: $rsr-white;
          margin: 0px;
          margin-bottom: toem(5px);
        }

        h4 {
          color: $rsr-secondary;
        }

        img {
          height: toem(60px);
        }
      }
    }
  }
}
.about-review {
  text-align: left;
  padding-top: 120px;
  padding-bottom: 120px;
  background: #F5F5F5;
  @include respond-to("iphone6-plus") {
    padding-top: 100px;
    padding-bottom: 100px;
  }

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__copy {
      margin-top: 20px;
      margin-bottom: 100px;
    }

    h2 {
      font-weight: 900;
    }
  }
  .comments-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .comment-container {
      width: 48%;
      background: $rsr-secondary;
      padding: 50px;
      padding-top: 30px;
      margin-bottom: 50px;
      @include respond-to("iphone6-plus") {
        width: 100%;
      }
      .logo {
        max-width: 120px;
      }
      p {
        font-size: 16px;
        margin: 0;
      }
      .author {
        display: flex;
        align-items: center;
        margin-top: 30px;
        img {
          width: 50px;
          height: 50px;
          margin-right: 20px;
        }
        .name {
          font-weight: bold;
        }
      }
    }
  }
}
.about-me {
  .content__img {
    @include column(1/2);
    background: url('../img/home/skylar-scream.png');
    background-repeat: no-repeat;
    background-position: center 100px;
    background-size: contain;
    height: 720px;
    @include respond-to("iphone6-plus") {
      @include column(1);
      height: 475px;
    }
  }
  .content__copy {
    @include column(1/2);
    padding-left: 100px;
    height: 720px;
    display: flex;
    align-items: center;
    @include respond-to("iphone6-plus") {
      width: 75%;
      padding-left: 0;
      height: 475px;
    }
  }
}
.about-footer {
  background-color: $rsr-primary;
  padding-top: toem(180px);
  padding-bottom: toem(180px);
  img {
    max-width: 700px;
    display: block;
    margin: 0 auto;
    @include respond-to("iphone6-plus") {
      max-width: 100%;
    }
  }
  img.skull {
    height: 419px;
    width: 363px;
    margin-bottom: 150px;
  }
  p {
    color:  $rsr-secondary;
    font-family: Theinhardt;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
  }
}
.me-abstract {
  background: #F5F5F5;
  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);
    @include respond-to("iphone6-plus") {
      @include column(1);
      h1 {
        font-size: 65px;
      }
    }
    text-align: left;
    .content__copy {
      margin-top: 55px;
      margin-bottom: 120px;
    }
  }
}
.me-details {
   background: #F5F5F5;
  .content__container {
    text-align: left;
    .content__copy {
      @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);
      @include respond-to("iphone6-plus") {
        @include column(1);
      }
      h2 {
        font-weight: 900;
      }
      margin-bottom: 80px;
    }
    .img-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 80px;
      &.multiple {
        margin-bottom: 120px;
        img {
          width: 48%;
          object-fit: cover;
        }   
      }
      @include respond-to("iphone6-plus") {
        img {
          width: 100%;
          max-height: 170.88px;
          object-fit: cover;
        }
      }
    }
  }
}