////
/// Ventures
////
.ventures-abstract {
  text-align: left;
  background: #F5F5F5;
  .content__container {
    text-align: left;
    padding-bottom: toem(120px);
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);
    @include respond-to("iphone6-plus") {
      @include column(1);
      padding-bottom: toem(100px);
    }
    .content__copy {
      margin-top: 55px;
    }
  }
}
.ventures-contents {
  background: #F5F5F5;
  .wrapper {
    .content__container {
      padding-bottom: toem(120px);
      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
        padding-top: toem(50px);
        padding-bottom: toem(100px);
      }
      .content__header {
        position: relative;
        max-height: 480px;
        img {
          object-fit: cover;
          max-height: 480px;
          width: 100%;
        }
      }
      .content__title {
        position: absolute;
        left: 60px;
        bottom: 60px;
        max-width: 600px;
      }
      h6 {
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
        color: $rsr-secondary;
      }
      h4 {
        color: $rsr-secondary;
      }
      .content-figures {
        position: absolute;
        right: 60px;
        bottom: 60px;
        display: flex;
        div {
          padding: 0 30px;
        }
        h2 {
          font-size: 60px;
          font-family: "Kommissar Web";
        }
        p {
          margin: 0;
        }
        h2, p {
          color: $rsr-secondary;
        }
      }
      .content__copy {
        padding-left: toem(50px);
        max-width: toem(500px);
        p {
          margin-top: toem(34px);
          margin-bottom: toem(50px);
          font-size: toem(16px);
        }
      }
      .cta {
        padding-left: toem(50px);
        text-align: left;
        a {
          img {
            display: inline;
            margin-left: toem(19px);
          }
        }
        p {
          padding-left: toem(26px);
          display: inline;
          font-size: toem(13px);
          font-style: italic;
          font-weight: 600;
        }
      }
      @include respond-to("iphone6-plus") {
        .content__header img {
          max-height: none;
          height: 200px;
        }
        .content__title {
          left: 15px;
          bottom: 15px;
          h6 {
            margin-top: 0;
          }
          h4 {
            font-size: 30px;
          }
        }
        .content-figures {
          right: 15px;
          bottom: 15px;
          div {
            width: 67px;
            padding: 0px;
            // padding-left: 5px;
            text-align: right;
          }
          h2 {
            font-size: 30px;
            line-height: normal;
          }
          p {
            font-size: 12px;
          }
        }
        .content__copy {
          padding-left: toem(30px);
          max-width: toem(300px);
        }
        .cta {
          padding-left: toem(30px);
        }
      }
    }
  }
}
.ventures-footer {
  background-color: $rsr-primary;
  padding-top: toem(180px);
  padding-bottom: toem(180px);
  img {
    max-width: 700px;
    display: block;
    margin: 0 auto;
  }
  p {
    color:  $rsr-secondary;
    font-family: Theinhardt;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 0;
  }
   @include respond-to("iphone6-plus") {
    img {
      max-width: 100%;
    }
   }
}
.venture-detail-abstract {
  background: #F5F5F5;
  max-height: 700px;
  position: relative;
  > img {
    object-fit: cover;
    width: 100%;
    max-height: 700px;
    height: 700px;
  }
  .wrapper {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding-bottom: 60px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    .content__title {
      flex: 1;
    }
    h6, h4 {
      color: $rsr-secondary;
    }
    h6 {
      font-size: 12px;
      letter-spacing: 4px;
      line-height: 14px;
    }
    h4 {
      font-weight: 900;
      text-shadow: 0 0 54px 0 #000000;
    }
    .cta {
      text-align: left;
      a {
        border: 1px solid white;
        display: flex;
        align-items: center;
        img {
          display: inline;
          margin-left: toem(30px);
        }
      }
    }
  }
  @include respond-to("iphone6-plus") {
    > img {
      height: 300px;
    }
    .wrapper {
      padding-bottom: 15px;
      .content__title {
        h6 {
          margin-top: 0;
        }
        h4 {
          font-size: 30px;
        }
      }
      .cta a {
        padding: 10px;
      }
    }
  }
}
.venture-company {
  background: #F5F5F5;
  padding: 80px 0px;
  .content-figures {
    @include column(4/6, $offset: 1/6);
    display: flex;
    div {
        margin-right: 60px;
      }
    h2 {
        color: #181818;
        font-family: "Kommissar Web";
        font-size: 60px;
        font-weight: 900;
        letter-spacing: 0;
        line-height: 72px;
      }
    p {
        font-size: 18px;
        font-weight: 500;
        letter-spacing: 0;
        line-height: 20px;
      }
    @include respond-to("iphone6-plus") {
      margin: 0;
      width: 100%;
      justify-content: space-evenly;
      div {
        margin: 0;
      }
    }
  }
  .content__container {
    width: 100%;
    .content__left {
      @include column(1/6);
      img {
        margin-top: 30px;
        width: 50%;
      }
      @include respond-to("iphone6-plus") {
        width: 50%;
        img {
          width: 100%;
        }
      }
    }
    .content__right {
      @include column(4/6);
      @include respond-to("iphone6-plus") {
        width: 100%;
      }
      .copy {
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 0;
        line-height: 40px;
      }
    }
  }
}
.big-thing, .venture-details {
  background: #F5F5F5;
  padding-bottom: toem(120px);
  .content__container {
    @include column(4/6, $offset: 1/6);
    @include respond-to("iphone6-plus") {
      margin-left: 0;
      width: 100%;
    }
    h2 {
      font-weight: 900;
    }
  }
}
.big-thing {
  padding-bottom: toem(80px);
}
.venture-img-top {
  background: #F5F5F5;
  padding-bottom: toem(120px);
  .content__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  img {
    width: 48%;
    object-fit: cover;
    &:first-child {
      width: 100%;
      margin-bottom: 45px;
      @include respond-to("iphone6-plus") {
        margin-bottom: 13px;
      }
    }
  }
}
.venture-img-bottom {
  background: #F5F5F5;
  padding-bottom: toem(120px);
  .content__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  img {
    width: 48%;
    height: 480px;
    margin-bottom: 45px;
    object-fit: cover;
    @include respond-to("iphone6-plus") {
      height: 165px;
      margin-bottom: 13px;
    }
  }
}
.effect-container {
  position: relative;
  z-index: 2;
  height: 100%;
  margin-bottom: 100vh;
  @include respond-to("iphone6-plus") {
    // margin-bottom: 304px;
  }
}
.venture-detail-footer {
  background-color: $rsr-primary;
  // padding-top: 130px;
  // padding-bottom: 110px;
  position: fixed;
  bottom: 0;
  width: 100%;
  z-index: -1;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .content__container {
    position: relative;
    max-height: 480px;
    img {
      object-fit: cover;
      width: 100%;
      max-height: 480px;
      height: 480px;
    }
    .content__title {
        position: absolute;
        left: 60px;
        bottom: 60px;
        max-width: 600px;
    }
    h6 {
      letter-spacing: 0.2em;
      margin-bottom: toem(12px);
      color: $rsr-secondary;
    }
    h4 {
      color: $rsr-secondary;
    }
    .content-figures {
      position: absolute;
      right: 60px;
      bottom: 60px;
      display: flex;
      div {
        padding: 0 30px;
      }
      h2 {
        font-size: 60px;
        font-family: "Kommissar Web";
      }
      p {
        margin: 0;
      }
      h2, p {
        color: $rsr-secondary;
      }
    }
  }
  @include respond-to("iphone6-plus") {
    padding-top: 50px;
    padding-bottom: 50px;
    min-height: none;
    .content__container {
      img {
        max-height: none;
        height: 200px;
      }
      .content__title {
        left: 15px;
        bottom: 15px;
        h6 {
          margin-top: 0;
        }
        h4 {
          font-size: 30px;
        }
      }
      .content-figures {
        right: 15px;
        bottom: 15px;
        div {
          width: 67px;
          padding: 0px;
          // padding-left: 5px;
          text-align: right;
        }
        h2 {
          font-size: 30px;
          line-height: normal;
        }
        p {
          font-size: 12px;
        }
      }
    }
  }
}