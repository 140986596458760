////
/// Footer
////

footer {
    @include respond-to('iphone6-plus') {
        padding: toem(50px) 0 toem(35px) 0;
    }
  @include clearfix();

  padding: toem(80px) 0 toem(35px) 0;

  text-align: center;

  color: $rsr-font-color-bright;
  background: $rsr-primary;
  .footer__nav {
    @include clearfix();
    .footer__item {
      @include column(1/4, $gutter: 2);
      @include respond-to('iphone6-plus') {
        @include column(1/2, $gutter: 0);

        text-align: left;
      }

      text-align: left;
      ul {
        margin: 0;
        padding: 0;

        list-style-type: none;
        li {
          display: block;

          line-height: 1;

          padding-bottom: toem(16px);
          &:last-child {
            padding-bottom: 0;
          }
          a {
            display: block;

            font-weight: 600;

            text-transform: uppercase;

            color: inherit;
            &:hover {
              color: shade($rsr-font-color-bright, 15%);
            }
          }
        }
      }
    }
    .footer__logo {
      @include respond-to('ismobile') {
        img {
          width: 75%;
        }
      }
      @include respond-to('iphone6-plus') {
        @include column(1/1, $gutter: 0);

        padding-bottom: toem(40px);
        img {
          width: 45%;
        }
      }
    }
    .footer__social {
        @include respond-to('iphone6-plus') {
            @include column(1/1, $gutter: 0);

            padding-top: toem(40px);
        }
      text-align: right;
      ul {
        li {
          display: inline-block;
        }
      }
    }
  }
  .footer__copy {
    @include respond-to('iphone6-plus') {
      @include column(1/1, $gutter: 0);

      padding-top: toem(32px);
    }

    display: block;

    padding-top: toem(80px);
    p {
      font-size: toem(14px);
      font-weight: 600;

      margin: 0;
      padding: 0 0 toem(7px) 0;

      color: $rsr-white;
      &:last-child {
        padding: 0;
      }
    }
    a {
      color: inherit;
      &:hover {
        color: shade($rsr-font-color-bright, 20%);
      }
    }
  }
}

.footer__social {
  @include respond-to('iphone6-plus') {
    @include column(1/1, $gutter: 0);

    padding-top: toem(40px);
  }

  ul {
    li {
      display: inline-block;

      margin-right: toem(16px);
      &:hover {
        svg path {
          transition: all .25s ease;

          fill: shade($rsr-font-color-bright, 15%);
        }
      }
      &:last-child {
        margin-right: 0;
      }
    }
  }
}
