////
/// Placeholders
/// @group Placeholders
////

///
@import "../core/settings";

///
%text--small {
  font-size: 80%
}
///
%text--left {
  text-align: left
}
///
%text--right {
  text-align: right
}
///
%text--center {
  text-align: center
}
///
%text--light {
  font-weight: $rsr-font-weight-light
}
///
%text--regular {
  font-weight: $rsr-font-weight-regular
}
///
%text--semibold {
  font-weight: $rsr-font-weight-semibold
}
///
%text--bold {
  font-weight: $rsr-font-weight-bold
}
///
%text--uppercase {
  text-transform: uppercase
}
