////
/// Modal Dialogs
/// @group Component - Modal Dialogs
////

///
$modal-backdrop-background:    rgba(#000, 0.7) !default;
///
$modal-content-background:     #fff !default;
///
$modal-transition-delay: $rsr-transition-duration !default;


/// Component Dialog - 
/// This is a CSS-only modal that takes
/// advantage of :target event to show
/// a modal-like component using HTML anchors.
/// @example
///   <a href="#modal">Show modal dialog</a>
///   <div class="c-modal" id="modal">
///     <a href="#!" class="c-modal__close">Close</a>
///     <div class="c-modal__content">Modal dialog content goes here.</div>
///   </div>
@mixin c-modal{
  .c-modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 9999;
    visibility: hidden;
    opacity: 0;
    background-color: $modal-backdrop-background;
    transition: visibility $rsr-transition-duration 0s, opacity $rsr-transition-duration $rsr-transition-timing;

    @at-root {
      &__content {
        @include align(both);

        margin-top: -20px;
        opacity: 0;
        background-color: $modal-content-background;
        transition: opacity $rsr-transition-duration $rsr-transition-timing $modal-transition-delay, margin $rsr-transition-duration $rsr-transition-timing $modal-transition-delay;
        width: 60%;
        height: 60%;
        background: transparent;
      }

      &__close {
        position: absolute;
        top: 20px;
        right: 20px;
        font-size: 48px;
        color: $rsr-primary;
        z-index: 10;
        &:hover {
          color: #fe915a;
        }
      }
    }

    // Show modal dialog and it's content
    &:target,
    &--is-visible {
      visibility: visible;
      opacity: 1;

      // Would be great to use '&' for consistency, but for some reason, it compiles to .modal:target__content
      .c-modal__content {
          margin-top: 0;
          opacity: 1;
      }
    }
  }
}
