////
/// Typography
/// Init typography basic settings
/// @group Typography
////

///
@import './settings';
///
@import '../modules/icons';


///
$rsr-headers-h1-font-size: toem(140px);
///
$rsr-headers-h2-font-size: toem(40px);
///
$rsr-headers-h3-font-size: toem(24px);
///
$rsr-headers-h4-font-size: toem(80px);
///
$rsr-headers-h5-font-size: toem(32px);
///
$rsr-headers-h6-font-size: toem(12px);
///
$rsr-headers-p-font-size: toem(18px);


html,
button {
  -webkit-font-smoothing: antialiased;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font: $rsr-font;
  font-family: $rsr-font-family;
  font-weight: 400;
}

h1 {
  @include respond-to('desktop-low') {
    font-size: toem(154px);
  }
  @include respond-to('iphone6-plus') {
    font-size: toem(90px);
  }

  font-size: $rsr-headers-h1-font-size;
  font-weight: $rsr-font-weight-bold;
  line-height: 0.9;
  font-family: $rsr-font-family-title;
  text-transform: uppercase;
  margin: 0;
  color: $rsr-primary;
}

h2 {
  @include respond-to('iphone6-plus') {
        font-size: toem(32px);
      }
  font-size: $rsr-headers-h2-font-size;
  font-weight: $rsr-font-weight-bold;
  line-height: 1.4;
  margin: 0;
  color: $rsr-primary;
}

h3 {
  font-size: $rsr-headers-h3-font-size;
  line-height: 1.6;
  color: $rsr-primary;
}

h4 {
  font-family: $rsr-font-family-ultra-title;
  font-size: $rsr-headers-h4-font-size;
  font-weight: $rsr-font-weight-ultrabold;
  line-height: 1.0;
  margin: 0;
  text-transform: uppercase;
  color: $rsr-primary
}

h5 {
  @include respond-to('iphone6-plus') {
        font-size: toem(24px);
      }
  font-family: $rsr-font-family-title;
  font-size: $rsr-headers-h5-font-size;
  font-weight: 700;
  line-height: 1;
  margin: 0;
  color: $rsr-senary;
}

h6 {
  font-size: $rsr-headers-h6-font-size;
  letter-spacing: 0.08em;
  font-weight: 700;
  line-height: 1;
  text-transform: uppercase;
  color: $rsr-primary;
}

p {
  font-size: $rsr-headers-p-font-size;
  line-height: 1.78;
  color: $rsr-primary;
}

strong {
  font-weight: $rsr-font-weight-bold;
}
