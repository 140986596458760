@charset 'UTF-8';

// Default Variables

// Slick icon entity codes outputs the following
// "\2190" outputs ascii character "←"
// "\2192" outputs ascii character "→"
// "\2022" outputs ascii character "•"

$slick-font-path: '../fonts/' !default;
$slick-font-family: 'slick' !default;
$slick-loader-path: './' !default;
$slick-arrow-color: white !default;
$slick-dot-color: $rsr-primary !default;
$slick-dot-color-active: $slick-dot-color !default;
$slick-prev-character: '\2190' !default;
$slick-next-character: '\2192' !default;
$slick-dot-character: '\2022' !default;
$slick-dot-size: 35px !default;
$slick-opacity-default: 1 !default;
$slick-opacity-on-hover: 1 !default;
$slick-opacity-not-active: .45 !default;

/* Slider */

.slick-list {
  .slick-loading & {
    background: #fff slick-image-url('../img/ajax-loader.gif') center center no-repeat;
  }
}

/* Arrows */

.slick-prev,
.slick-next {
  display: block;

  position: absolute;
  top: 50%;

  font-size: 0;
  line-height: 0;

  width: 20px;
  height: 20px;
  padding: 0;

  cursor: pointer;
  -webkit-transform: translate(0, -50%);
      -ms-transform: translate(0, -50%);
          transform: translate(0, -50%);

  color: transparent;
  border: none;
  outline: none;
  background: transparent;
  &:hover,
  &:focus {
    color: transparent;
    outline: none;
    background: transparent;
    &:before {
      opacity: $slick-opacity-on-hover;
    }
  }
  &.slick-disabled:before {
    opacity: $slick-opacity-not-active;
  }
  &:before {
    font-family: $slick-font-family;
    font-size: 20px;
    line-height: 1;

    opacity: $slick-opacity-default;
    color: $slick-arrow-color;

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
}

.slick-prev {
  left: -25px;
  [dir='rtl'] & {
    right: -25px;
    left: auto;
  }
  &:before {
    content: $slick-prev-character;
    [dir='rtl'] & {
      content: $slick-next-character;
    }
  }
}

.slick-next {
  right: -25px;
  [dir='rtl'] & {
    right: auto;
    left: -25px;
  }
  &:before {
    content: $slick-next-character;
    [dir='rtl'] & {
      content: $slick-prev-character;
    }
  }
}

/* Dots */

.slick-dotted.slick-slider {
  margin-bottom: 30px;
}

.slick-dots {
  @include respond-to('iphone6-plus') {
    bottom: 0;
  }

  display: block;

  position: absolute;
  bottom: 35px;

  width: 100%;
  margin: 0;
  padding: 0;

  list-style: none;

  text-align: center;
  li {
    display: inline-block;

    position: relative;

    width: 12px;
    height: 12px;
    margin: 0 5px;
    padding: 0;

    cursor: pointer;
    transition: all .25s cubic-bezier(0,.15,1,1.05);
    button {
      display: block;

      font-size: 0;
      line-height: 0;

      width: 12px;
      height: 12px;
      padding: 5px;

      cursor: pointer;
      transition: all .25s cubic-bezier(0,.15,1,1.05);

      color: transparent;
      border: 0;
      outline: none;
      background: transparent;
      &:hover,
      &:focus {
        outline: none;
        &:before {
          opacity: $slick-opacity-on-hover;
        }
      }
      &:before {
        position: absolute;
        top: 0;
        left: 0;

        font-family: $slick-font-family;
        font-size: $slick-dot-size;
        line-height: 20px;

        width: 12px;
        height: 12px;

        content: $slick-dot-character;
        transition: all .25s cubic-bezier(0,.15,1,1.05);
        text-align: center;

        opacity: $slick-opacity-not-active;
        color: $rsr-octonary;

        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
      }
    }
    &.slick-active button:before {
      opacity: $slick-opacity-default;
      color: $slick-dot-color-active;
    }
  }
}


/* Slider */

.slick-slider {
  display: block;

  position: relative;

  box-sizing: border-box;

  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}
.slick-list {
  display: block;
  overflow: hidden;

  position: relative;

  margin: 0;
  padding: 0;
  &:focus {
    outline: none;
  }
  &.dragging {
    cursor: pointer;
    cursor: hand;
  }
}
.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
     -moz-transform: translate3d(0, 0, 0);
      -ms-transform: translate3d(0, 0, 0);
       -o-transform: translate3d(0, 0, 0);
          transform: translate3d(0, 0, 0);
}

.slick-track {
  display: block;

  position: relative;
  top: 0;
  left: 0;
  &:before,
  &:after {
    display: table;

    content: '';
  }
  &:after {
    clear: both;
  }
  .slick-loading & {
    visibility: hidden;
  }
}
.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
  [dir='rtl'] & {
    float: right;
  }
  img {
    display: block;
  }
  &.slick-loading img {
    display: none;
  }
  &.dragging img {
    pointer-events: none;
  }
  .slick-initialized & {
    display: block;
  }
  .slick-loading & {
    visibility: hidden;
  }
  .slick-vertical & {
    display: block;

    height: auto;

    border: 1px solid transparent;
  }
}
.slick-arrow.slick-hidden {
  display: none;
}
