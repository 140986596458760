////
/// RW Detail Page
////

/// Section Entrepreneur
.venture-rw--bg {

  //Fix the header spacer issue for detail pages
  margin-top: toem(-178px);
  padding: toem(88px) 0;

  background: url('../img/ventures/rw/lukeskylar-bg.jpg');
  background-repeat: no-repeat;
  background-position: center bottom;
  background-size: cover;

  @include respond-to("iphone6-plus") {
    background-position: left bottom;
  }

  .wrapper {
    .content__container {
      padding-top: toem(350px);
      h4 { color: $rsr-secondary; }
      h6 {
        color: $rsr-secondary;
        letter-spacing: 0.2em;
        margin-bottom: toem(12px);
      }
    }
  }
}

/// Section Entrepreneur
.venture-rw-abstract {
  text-align: left;
  padding-top: 150px;
  padding-bottom: 150px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }
    img {
      max-width: toem(120px);
    }
  }
  .content__container {
    @include column(4/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }
    h3 {  margin: 0; }
  }
}


/// Section The Opportunity
.venture-rw-opportunity {
  text-align: left;
  padding-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }

    .content__articles {
      padding-bottom: toem(50px);

      .article {
        padding-bottom: toem(200px);
        .image-left {
          @include column(1/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-bottom: toem(25px);
          }
        }

        .description-right {
          padding-top: toem(10px);
          padding-left: toem(50px);
          @include column(4/6, $cycle: 6);

          @include respond-to("ismobile") {
            @include column(1);
            padding-top: toem(0px);
            padding-left: toem(0px);
            padding-bottom: toem(75px);
          }
          h3 {
            margin: 0;
            padding: 0;
            padding-bottom: toem(7px);
            font-weight: 800;
          }
          p {
            margin: 0;
            padding: 0;
            font-weight: 500;
            line-height: 1;
            letter-spacing: 0.08em;
            font-size: toem(12px);
            padding-bottom: toem(10px);
          }
        }
      }
    }
  }
}

/// Full Image Break
.venture-rw-opportunity-image {
  .content__image__full {
    img {
      @include column(1, $cycle: 1);
    }
  }
}


/// Section The Story
.venture-rw-story {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

.venture-rw-story-full {
  .content__image__full {
    img {
      @include column(1, $cycle: 1);
    }
  }
}

.venture-rw-story-two {
  margin-top: toem(25px);

  .content__image__two {
    img {
      @include column(1/2, $cycle: 2);

      @include respond-to("iphone6-plus") {
        display: none;
      }
    }
  }
}


/// Section The Story
.venture-rw-story-nosidebar {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}


.venture-rw-story-bg {
  padding: toem(300px) 0;
  background: url('../img/ventures/rw/fullwidth.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  @include respond-to("iphone6-plus") {
    background-position: right;
  }
}



/// Section The Venture
.venture-rw-venture {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .sidebar {
    @include column(1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      display: none;
    }

    a {
      letter-spacing: 0.08em;
      color: $rsr-primary;
      font-size: toem(12px);;
      font-weight: 500;
      line-height: 1;
      text-transform: uppercase;

      &:hover {
        color: $rsr-senary;
      }

      &.active {
        font-weight: 1000;
      }
    }
  }

  .content__container {
    @include column(4/6, $gutter: 0, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

.venture-rw-venture-full {
  .content__image__full {
    img {
      @include column(1, $cycle: 1);
    }
  }
}

.venture-rw-venture-nosidebar {
  text-align: left;
  margin-top: 150px;
  margin-bottom: 50px;

  .content__container {
    @include column(4/6, $offset: 1/6, $gutter: 1, $cycle: 6);

    @include respond-to("iphone6-plus") {
      @include column(1);
    }

    .content__subsection {
      padding-bottom: toem(100px);

      .content__copy {
        margin-top: 20px;
      }
    }
  }
}

.venture-rw-venture-two {
  .content__image__two {
    img {
      @include column(1/2, $cycle: 2);

      @include respond-to("iphone6-plus") {
        @include column(1, $cycle: 1);
        margin-top: toem(25px);
      }
    }
  }
}


//Thoughts Footer
.thoughts-footer {
  background: $rsr-primary;
  padding-top: toem(100px);
  padding-bottom: toem(100px);

  .content__container {

    .content__image__full {
      img {
        @include column(1, $cycle: 1);
      }

      @include respond-to("iphone6-plus") {
        display: none;
      }
    }
    .content__image__full__mobile {
      display: none;
      img {
        @include column(1, $cycle: 1);
      }

      @include respond-to("iphone6-plus") {
        display: block;
      }
    }
  }
}